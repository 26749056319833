const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
        api: "https://ninhthuansmartcity-api.cgis.asia/",
        media_url: "https://ninhthuansmartcity-api.cgis.asia/",
        domainAdminSide: "http://localhost:3000",
        domainUserSide: "http://localhost:3001",
        domainName: "",
        workSpace: "dttm_ninhthuan",
        wmsBaseLink: "https://geo.cgis.asia/geoserver/dttm_ninhthuan/wms/",
  },
  production: {
      api: "https://ninhthuansmartcity-api.cgis.asia/",
      media_url: "https://ninhthuansmartcity-api.cgis.asia/",
      domainAdminSide: "https://admin.ninhthuansmartcity.cgis.asia",
      domainUserSide: "https://ninhthuansmartcity.cgis.asia",
      domainName: "ninhthuansmartcity.cgis.asia",
      workSpace: "dttm_ninhthuan",
      wmsBaseLink: "https://geo.cgis.asia/geoserver/dttm_ninhthuan/wms/",
  },
};

module.exports = apiEnvironment[env];
